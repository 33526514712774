import React, {Component} from "react";

class Auth extends Component {
    render() {
        let {loginFunc, registrationForm, login, passwd, passwd2, changeRegOrEnter, showRegistr, loginCompleted} = this.props;
        let clN, clNButton,done = '';
        let registerError = this.props.registerError;
        let reg = [];

        if (loginCompleted === true) {
            done = 'isLogined';
        }
        else{
            done = '';
        }

        if (showRegistr === true) {
            clN = 'left-part d-flex flex-column align-content-center justify-content-center block-pos-left to-right';
            clNButton = "Войти";
        } else {
            clN = 'left-part d-flex flex-column align-content-center justify-content-center block-pos-left';
            clNButton = "Зарегистрироваться";
        }

        let ff = registerError.split(','); // разбиваем строку полученную от родителя с ошибками

        ff.forEach((e) => {
            let keyRand = this.props.login + Math.random() * (10000 - 1) + 1;
            if (e.length > 0) {
                reg.push(React.createElement('li', {className: 'listItems', key: keyRand}, null, `${e}`));
            }
        });

        return (
            <div className="formAuth d-flex flex-row">
                <div className="f-auth d-flex flex-column align-content-center justify-content-center">
                    <div className="title-form">Регистрация нового пользователя</div>
                    <div className="text-podsk">*Используйте только ваши данные для регистрации</div>
                    <form className="d-flex flex-column ">
                        <input onChange={login} name="login" type="text" placeholder="Введите ваш логин"/>
                        <input onChange={passwd} type="password" name="passwd" placeholder="Введите ваш пароль"/>
                        <input onChange={passwd2} type="password" name="passwd2" placeholder="Повторите ваш пароль"/>
                        <ul className="errorList">{reg}</ul>
                        <button onClick={registrationForm} className="redBorder">Зарегистрироваться</button>

                    </form>
                </div>

                <div className={clN}>
                    <div className="title-form">Добро пожаловать</div>
                    <div className="registration-text">
                        Если вы еще не зарегистрированы, то самое время заняться этим!
                    </div>
                    <button onClick={changeRegOrEnter} className="button-registration whiteBorder"> {clNButton}</button>
                </div>

                <div className="f-auth d-flex flex-column align-content-center justify-content-center">
                    <div className={done}></div>
                    <div className="title-form">Войти в личный кабинет</div>
                    <div className="text-podsk">*Используйте только ваши данные</div>
                    <form className="d-flex flex-column ">
                        <input onChange={login} name="login" type="text" placeholder="Введите ваш логин"/>
                        <input onChange={passwd} type="text" name="passwd" placeholder="Введите ваш пароль"/>
                        <button onClick={loginFunc} className="redBorder">Войти</button>
                        <div className="errorList"></div>
                    </form>
                </div>


            </div>
        );
    }


}

export default Auth;