import React, {Component} from 'react';

class BotList extends Component {
    render() {
        const { botName,clickByThisBot,index, deleteBot} = this.props;
        return (
            <li data-index={index}>
                <span onClick={clickByThisBot}>{botName}</span>
                <div onClick={deleteBot} className="delete"></div>
            </li>
        )
    }
}

export default BotList;