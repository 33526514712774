import React from 'react';
import Userbots from "../../components/userBots/userbots";
import './user-style.scss';

class UserSettings extends React.Component {
    render() {
        let {userId} = this.props; // передаем секретный ключ от залогиненого юзера

        return (
            <div className="userSettings">
                <div className="container">
                    <div className="row customBoxShadow">
                        <div className="col-lg-3 listSitesAndSearch">
                            <div className="search">
                                <input onChange={this.search} type="text" placeholder="Поиск"/>
                            </div>
                            <div className="list-sites">
                                <div className="title">Сайты которые уже добавлены</div>
                                <ul>
                                    <li>БОТЫ</li>
                                    <li>НАСТРОЙКИ</li>
                                    <li>ОПЛАТА</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <Userbots userId={userId}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserSettings;