import React, {Component} from 'react';
import BotList from "../comp/bot-list";
import BotChange from "./botChange";

import './botList-style.scss';

class Userbots extends Component {

    constructor(props) {
        super(props);

        this.state = {
            botCustomise: false,
            userId: props.userId || '',
            type: 'getAllUserBots',
            botsList: [],
            curentBotName: '',
            curentBotKey: ''
        };

        this.getAllUserBots = this.getAllUserBots.bind(this);
        this.clickByThisBot = this.clickByThisBot.bind(this);
        this.botNameChange = this.botNameChange.bind(this);
        this.botApiKeyChange = this.botApiKeyChange.bind(this);
        this.saveBotChanges = this.saveBotChanges.bind(this);
        this.createBot = this.createBot.bind(this);
        this.closeBotSettings = this.closeBotSettings.bind(this);
        this.deleteBot = this.deleteBot.bind(this);



        this.ajaxFunc = this.ajaxFunc.bind(this);


    }

    /* Выполняет типичные Ajax pапросы*/
    ajaxFunc(){
        return fetch('http://allparser.ru/backend/index.php', {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(results => {
                if (results.result === true) {
                    let res = results.result;
                    return res;
                }
            });

    }


    closeBotSettings(e) {
        e.preventDefault();
        this.setState({botCustomise: false})
    }

    createBot(e) {
        e.preventDefault();
        let counterUserBot = this.state.botsList.length;

        let newBot = {
            botApiKey: "Default bot api key",
            botName: "New default bot"
        };

        this.setState({
            botCustomise: true,
            curentBot: counterUserBot,
            newBot: true,
            curentBotName: newBot.botName,
            curentBotKey: newBot.botApiKey
        });

        let newBotsList = this.state.botsList;
        newBotsList.push(newBot);

        this.setState({botsList: newBotsList},()=>{
            let res = this.ajaxFunc();
            res.then( (data)=>{
                    if(data === true){
                    }else{
                        console.log('чет не так');
                    }

                },
                ()=>{
                    console.log('dsfsd');
                });
        });

        // доработать

    }

    deleteBot(e) {
        e.preventDefault();
        var targetIndex = e.target.parentNode.dataset.index;
        var newListBot = this.state.botsList;


        this.setState({
            curentBot: targetIndex,
            type: 'deleteUserBots'
        },()=>{
            let res = this.ajaxFunc();
            res.then((data)=>{
                if(data === true){
                    newListBot.splice(targetIndex,1);
                    this.setState({botsList: newListBot});
                }else{
                    console.log('чет не так');
                }

            },()=>{
                console.log('error ');
            });

        });
    }

    saveBotChanges(e) {
        e.preventDefault();

        this.setState({type: 'saveBotChanges'}, (state) => {
            this.ajaxFunc();
        });

    }

    botNameChange(e) {
        e.preventDefault();
        let st = this.state.botsList;
        st[this.state.curentBot].botName = e.target.value;
        this.setState({botsList: st, curentBotName: e.target.value});
    }

    botApiKeyChange(e) {
        e.preventDefault();
        let st = this.state.botsList;
        st[this.state.curentBot].botApiKey = e.target.value;
        this.setState({botsList: st, curentBotKey: e.target.value});
    }

    getAllUserBots() {
        fetch('http://allparser.ru/backend/index.php', {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(results => {
                if (results.result === true) {
                    this.setState({botsList: results.botList});
                }
            });
    }

    clickByThisBot(e) {
        e.preventDefault();

        let targetIndex = e.target.parentNode.dataset.index;
        this.setState({
            botCustomise: true,
            curentBot: targetIndex,
            curentBotName: this.state.botsList[targetIndex].botName,
            curentBotKey: this.state.botsList[targetIndex].botApiKey
        });
    }

    componentDidMount() {
        this.getAllUserBots();
    }

    render() {

        let botCustomase = '';
        let classBlockToSettings = '';

        if (this.state.botCustomise === true) {
            botCustomase = <BotChange curentBotName={this.state.curentBotName} curentBotKey={this.state.curentBotKey}
                                      botApiKeyChange={this.botApiKeyChange} botNameChange={this.botNameChange}
                                      saveBotChanges={this.saveBotChanges}/>;
            classBlockToSettings = "wrapper-bot-settings active";
        } else {
            classBlockToSettings = "wrapper-bot-settings";
        }

        return (
            <div className="userbots">
                <h3>Список телеграм ботов для готовых для получения информации</h3>
                <div className="wrapper-bot">
                    <div className="wrapper-bot-list">
                        <ul>
                            {this.state.botsList.map((item, index) => (
                                <BotList key={index} index={index} clickByThisBot={this.clickByThisBot}
                                         botApiKey={item.botApiKey} botName={item.botName} deleteBot={this.deleteBot}/>
                            ))}
                        </ul>
                        <button onClick={this.createBot}>Add bot</button>
                    </div>
                    <div className={classBlockToSettings}>
                        <div onClick={this.closeBotSettings} className="close-settings"></div>
                        {botCustomase}
                    </div>
                </div>
            </div>

        )
    }
}

export default Userbots;