import React, {Component} from 'react';

class ListItem extends Component {
    render() {
        const {clickByListItem, id, title, index, parsing_access} = this.props;
        let StopParsing='';
        if(parsing_access === false){
            StopParsing = 'Stoped';
        }
        return (
            <li data-index={index} onClick={clickByListItem} className={id + ' ' +StopParsing}>
                {title}
            </li>
        )
    }
}

export default ListItem;