import React from 'react';
import Auth from '../../components/auth/auth';
import ModalsForm from '../../components/modals/form-modals';

class Home extends React.Component {

    render() {
        let keyRand = this.props.login + Math.random() * (10000 - 1) + 1;
        let errorDisplay = this.props.isError ?
            <ModalsForm closeModals={this.props.closeModals} showBoxMessage={this.props.isActive} key={keyRand}
                        isError={this.props.isError}/> : '';
        return (
            <div className="homeWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>
                                Здравствуйте, вы находитесь на домашней странице парсера сайтов
                            </h1>
                            <p>
                                Для дальнейшего использования сайта, вам нужно авторизоваться
                            </p>
                            <Auth registerError={this.props.registerError}
                                  showRegistr={this.props.showRegistr}
                                  changeRegOrEnter={this.props.changeRegOrEnter}
                                  loginFunc={this.props.loginFunc}
                                  registrationForm={this.props.registrationForm}
                                  login={this.props.login}
                                  loginCompleted={this.props.loginCompleted}
                                  passwd={this.props.passwd}
                                  passwd2={this.props.passwd2}
                            />
                            {errorDisplay}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;