import React, {Component} from 'react';

class BotChange extends Component {
    render() {
        let {botNameChange, botApiKeyChange,curentBotName,curentBotKey,saveBotChanges} = this.props;
        return (
            <div className="customSettingsToBot">
                <form>
                    <h4>Настройки Бота</h4>
                    <ul>
                        <li>
                            <span>Название Бота</span>
                            <input onChange={botNameChange} type="text" name="botName"
                                   value={curentBotName}/>
                        </li>
                        <li>
                            <span>API key бота</span>
                            <input onChange={botApiKeyChange} type="text" name="botApiKey"
                                   value={curentBotKey}/>
                        </li>
                    </ul>
                    <button className='save-bot redBorder' onClick={saveBotChanges}>Save</button>
                </form>
            </div>
        )
    }
}

export default BotChange;