import React, {Component} from "react";
import './modals-style/modals-form.css';
import Icons from '../../img/error.svg';

class ModalsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: props.showBoxMessage || 'false'
        }
    }


    render() {
        let clName = '';

        if (this.state.isActive === true) {
            clName = "active wrapperModals";
        } else {
            clName = "wrapperModals";
        }

        return (
            <div className={clName}>
                <div className="ModalsFormWrapper">
                    <div onClick={this.props.closeModals} className="close">
                        x
                    </div>
                    <div className="ModalsFormWrapper--modal-message">
                        <div className='header-top'>
                            <img src={Icons} alt=""/>
                            <span> Внимание, что-то пошло не по плану! </span>
                        </div>
                        <div className="body-modals-block">
                            {this.props.isError};
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ModalsForm;