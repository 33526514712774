import React, {Component} from 'react';

class ChangeButtons extends Component {
    render() {
        const {showBlockExample, saveChangesSoc, deleteSocial} = this.props;

        return (
            <div className="col-lg-12 text-right row-line">
                <button onClick={showBlockExample} className="redBorder">Пример парсинга
                </button>
                <button onClick={saveChangesSoc} className="redBorder">Сохранить изменения
                </button>
                <button onClick={deleteSocial} className="deleteParser redBorder">Удалить парсер</button>
            </div>
        );
    }
}

export default ChangeButtons;