import React from 'react';
import {Link} from 'react-router-dom'

let NavAuth = (props) => {
    return (
        <nav key="NavAuth">
            <ul className="d-flex justify-content-end">
                <li><Link to='/faq'>FAQ</Link></li>
                <li><Link to='/social'>Социальные сети</Link></li>
                <li><Link to='/sites'>Сайты</Link></li>
                <li><Link to='/rss'>Rss ленты</Link></li>
                <li>
                    <button
                        className="authorise clearStyle">
                        {props.login}
                    </button>
                    <ul>
                        <li><Link to='/user-settings'>Настройки профиля</Link></li>
                        <li>
                            <button
                                className="logoutButton"
                                onClick={props.logoutFunc}>
                                Выйти
                            </button>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};


export default NavAuth;