import React, {Component} from 'react';
import ChangeButtons from "../buttons/change";
import CreateButtons from "../buttons/create";


class SocInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showBlock: false,
            type:'',
            typeOfButton: props.typeOfButton || '',
            classListExample: "wrapper-test-sites",
            id: props.data.id || '',
            md5_uniq: props.data.md5_uniq || '',
            title: props.data.title || '',
            category_id: props.data.category_id || '',
            description: props.data.description || '',
            count: props.data.count || '',
            domain: props.data.domain || '',
            extended: props.data.extended || '',
            v: props.data.v || '',
            output: props.data.output || ''
        };

        this.changeTitle = this.changeTitle.bind(this);
        this.changeDomain = this.changeDomain.bind(this);
        this.changeDescr = this.changeDescr.bind(this);
        this.changeCounter = this.changeCounter.bind(this);
        this.changeExtended = this.changeExtended.bind(this);
        this.changeV = this.changeV.bind(this);
        this.changeOutput = this.changeOutput.bind(this);


        this.deleteSocial = this.deleteSocial.bind(this);
        this.saveChangesSoc = this.saveChangesSoc.bind(this);
        this.showBlockExample = this.showBlockExample.bind(this);

        this.testParse = this.testParse.bind(this);
        this.createSocial = this.createSocial.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }


    changeTitle(e) {
        this.setState({title: e.target.value});
    }

    changeOutput(e) {
        this.setState({output: e.target.value});
    }

    changeDomain(e) {
        this.setState({domain: e.target.value});
    }

    changeDescr(e) {
        this.setState({description: e.target.value});
    }

    changeCounter(e) {
        this.setState({count: e.target.value});
    }

    changeExtended(e) {
        this.setState({extended: e.target.value});
    }

    changeV(e) {
        this.setState({v: e.target.value});
    }


    // управление кнопками

    testParse(e) {
        e.preventDefault();
        console.log("function: createSocial");

    }
    clearForm(e) {
        e.preventDefault();
        console.log("function: createSocial");

    }
    createSocial(e) {
        e.preventDefault();
        console.log("function: createSocial");

    }

    showBlockExample(e) {
        e.preventDefault();
        console.log("function: showBlockExample");


        console.log(this.state);
    }

    saveChangesSoc(e) {
        e.preventDefault();
        console.log("function: saveChangesSoc");

        this.setState({type: 'saveChangesSoc'},function () {

            fetch("http://allparser.ru/backend/index.php", {
                method: "POST",
                    body: JSON.stringify(this.state),
                    headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                    },
                    (error) => {
                        console.log(error);
                    }
                );

        });

    }

    deleteSocial(e) {
        e.preventDefault();
        console.log("function: deleteSocial");

        this.setState({type: 'deleteSocial'},function () {

            fetch("http://allparser.ru/backend/index.php", {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                    },
                    (error) => {
                        console.log(error);
                    }
                );

        });



    }


    render() {
        // let titleExampleBlock = "Просмотр парсера социальной сети: " + this.state.title;
        let buttons = '';

        if (this.state.typeOfButton === "change") {
            buttons = <ChangeButtons
                showBlockExample={this.showBlockExample}
                saveChangesSoc={this.saveChangesSoc}
                deleteSocial={this.deleteSocial}
            />;
        } else {
            buttons = <CreateButtons
                testParse={this.testParse}
                createSocial={this.createSocial}
                clearForm={this.clearForm}/>;
        }


        return (
            <div className="siteInfo-wrapper">
                <div className="container">
                    <div className="row">
                        <form className="Froma-item-list">

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Название группы:</span>
                                <input className="text-block"
                                       onChange={this.changeTitle}
                                       name="title"
                                       type="text"
                                       value={this.state.title}
                                       placeholder="Введите название группы"
                                />
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Домен:</span>
                                <input onChange={this.changeDomain}
                                       name="domain"
                                       className="text-block"
                                       type="text"
                                       value={this.state.domain}
                                       placeholder="Введите domain страницы"/>
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Описание: </span>
                                <input onChange={this.changeDescr}
                                       name="description"
                                       className="text-block"
                                       type="text"
                                       value={this.state.description}
                                       placeholder="Введите описание группы"/>

                            </div>
                            <div className="col-lg-12 row-line">
                                <span className="title_name">Количество новостей в фиде: </span>
                                <input onChange={this.changeCounter}
                                       name="count"
                                       className="text-block"
                                       type="text"
                                       value={this.state.count}
                                       placeholder="Введите количество последних новостей для парсинга"/>
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Распишенное API: </span>
                                <input onChange={this.changeExtended}
                                       name="extended"
                                       className="text-block"
                                       type="text"
                                       value={this.state.extended}
                                       placeholder="Введите селектор Заголовка"/>
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Версия API: </span>
                                <input onChange={this.changeV} name="v" className="text-block"
                                       type="text"
                                       value={this.state.v}
                                       placeholder="Введите версию API"/>
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Куда выводим: </span>
                                <input onChange={this.changeOutput} name="output" className="text-block"
                                       type="text"
                                       value={this.state.output}
                                       placeholder="Введите место вывода"/>
                            </div>

                            <div className="col-lg-12 text-right row-line">
                                {buttons}
                            </div>
                            <div className="col-lg-12 text-right row-line">
                                {/*{resTextAjax}*/}
                            </div>
                        </form>
                    </div>
                </div>
                {/*<div className={this.state.classListExample}>*/}
                {/*    <div onClick={this.hiddenBlock} className="close">x</div>*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-lg-12">*/}
                {/*            <div className="title-example-block">*/}
                {/*                {titleExampleBlock}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="exampleBlock-parser">*/}
                {/*            Example*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }

}

export default SocInfo;