import React, {Component} from 'react';


class CreateButtons extends Component {
    render() {
        const {testParse, createSocial, clearForm} = this.props;
        return (
            <div className="col-lg-12 text-right row-line">
                <button onClick={testParse} className="redBorder">Пробный парсинг
                </button>
                <button onClick={createSocial} className="redBorder">Создать
                </button>
                <button onClick={clearForm} className="deleteParser redBorder">Очистить</button>
            </div>
        )
    }
}

export default CreateButtons;