import React from 'react';


class Footer extends React.Component {
    render() {
        return (
            <footer className="Footer">
                <div className="container">
                    <div className="d-flex flex-row">
                        <div className="p-1">
                            Footer
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;