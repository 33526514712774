import React, {Component} from 'react';

class ChangeButtons extends Component {
    render() {
        const {showBlockExample, saveChanges, deleteSites, AvailibleParsing, ActiveParsingOrNot} = this.props;
        let activeBtn = '';
        let AvailibleParsingText = 'Выкл';

        if(ActiveParsingOrNot === false){
            activeBtn = 'disableBtn';
            AvailibleParsingText = 'Вкл';
        }else{
            activeBtn = '';
            AvailibleParsingText = 'Выкл';
        }

        return (
            <div className="col-lg-12 text-right row-line">
                <button onClick={AvailibleParsing} className={"redBorder " + activeBtn}>{AvailibleParsingText}</button>
                <button onClick={showBlockExample} className="redBorder">Посмотреть пример парсинга</button>
                <button onClick={saveChanges} className="redBorder">Сохранить изменения</button>
                <button onClick={deleteSites} className="deleteParser redBorder">Удалить парсер</button>
            </div>
        );
    }
}

export default ChangeButtons;