import React, {Component} from 'react';
import ChangeButtons from "../buttons/change";
import CreateButtons from "../buttons/create";
import ListItemExample from "../../../../components/comp/list-example-site";
import Select from 'react-select';
// import AsyncSelect from 'react-select/lib/Async';

// import BotList from "../../../../components/comp/bot-list";


class SiteInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showBlock: false,
            classListExample: "wrapper-test-sites",
            titleBlockExample: 'Парсим сайт: ',
            data: props.data || '',
            userId: props.userId,
            dataExample: [],
            block_selector: props.data.block_selector || '',
            title: props.data.title || '',
            domain: props.data.domain || '',
            url: props.data.url || '',
            h1_selector: props.data.h1_selector || '',
            link_selector: props.data.link_selector || '',
            text_selector: props.data.text_selector || '',
            xml_shema: props.data.xml_shema || '',
            xml_shema_name: props.data.xml_shema_name || '',
            url_formula: parseInt(props.data.url_formula) || 1,
            rss: props.data.rss || '',
            telegram: props.data.telegram || '',
            api_key: props.data.api_key || '',
            type: '',
            resultAction: '',
            typeOfButton: props.typeOfButton || '',
            listSites: props.listSites
        };

        this.hiddenBlock = this.hiddenBlock.bind(this);

        this.showBlockExample = this.showBlockExample.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.deleteSites = this.deleteSites.bind(this);

        this.createSite = this.createSite.bind(this);
        this.clearForm = this.clearForm.bind(this);

        this.changeName = this.changeName.bind(this);
        this.changeUrl = this.changeUrl.bind(this);
        this.changeDomain = this.changeDomain.bind(this);
        this.changeH1Selector = this.changeH1Selector.bind(this);
        this.changeLinkSelector = this.changeLinkSelector.bind(this);
        this.changeTextSelector = this.changeTextSelector.bind(this);
        this.changeXmlShema = this.changeXmlShema.bind(this);
        this.changeXmlShemaName = this.changeXmlShemaName.bind(this);
        this.changeRss = this.changeRss.bind(this);
        this.changeTelegram = this.changeTelegram.bind(this);
        this.changeApiKey = this.changeApiKey.bind(this);
        this.changeBlockSelector = this.changeBlockSelector.bind(this);
        this.changeBlockSel = this.changeBlockSel.bind(this);
        this.onstartSelect = this.onstartSelect.bind(this);

        this.getSiteParseExample = this.getSiteParseExample.bind(this);
        this.AvailibleParsing = this.AvailibleParsing.bind(this);
        this.GetAllBots = this.GetAllBots.bind(this);
    }

    getSiteParseExample() {
        fetch("http://allparser.ru/backend/parseExample.php", {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({dataExample: result});
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    onstartSelect(e) {
        e.preventDefault();
    }

    hiddenBlock(e) {
        e.preventDefault();
        this.setState({classListExample: 'wrapper-test-sites'});
    }

    createSite(e) {
        e.preventDefault();

        this.setState({type: "createSite"}, (state) => {
            fetch('http://allparser.ru/backend/index.php', {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(results => {
                        console.log(results);
                        if (results.result === true) {
                            this.setState({resultAction: results.result});
                            this.state.listSites();
                        }
                    },
                    (error) => {
                        console.log(error);
                    });

        });
    }

    saveChanges(e) {
        e.preventDefault();

        this.setState({type: "SaveSite"}, (state) => {
            fetch('http://allparser.ru/backend/index.php', {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(results => {
                        if (results.result === true) {
                            this.setState({resultAction: results.result});
                            this.state.listSites();
                        }
                    },
                    (error) => {
                        console.log(error);
                    });

        });
    }

    deleteSites(e) {
        e.preventDefault();

        this.setState({type: "deleteSites"}, (state) => {
            fetch('http://allparser.ru/backend/index.php', {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(results => {
                        if (results.result === true) {
                            this.setState({resultAction: results.result});
                            this.state.listSites();
                        }
                    },
                    (error) => {
                        console.log(error);
                    });

        });
    }

    clearForm(e) {
        e.preventDefault();

        this.setState({
            data: '',
            block_selector: '',
            title: '',
            domain: '',
            url: '',
            h1_selector: '',
            link_selector: '',
            text_selector: '',
            xml_shema: '',
            xml_shema_name: '',
            rss: '',
            telegram: '',
        });
    }

    showBlockExample(e) {
        e.preventDefault();
        this.setState(
            {classListExample: 'wrapper-test-sites active', type: 'getExampleParse'},
            () => {
                this.getSiteParseExample();
            }
        );
    }

    changeName(e) {
        this.setState({title: e.target.value});
    }

    changeUrl(e) {
        this.setState({url: e.target.value});
    }

    changeDomain(e) {
        this.setState({domain: e.target.value});
    }

    changeH1Selector(e) {
        this.setState({h1_selector: e.target.value});
    }

    changeLinkSelector(e) {
        this.setState({link_selector: e.target.value});
    }

    changeTextSelector(e) {
        this.setState({text_selector: e.target.value});
    }

    changeXmlShema(e) {
        this.setState({xml_shema: e.target.value});
    }

    changeXmlShemaName(e) {
        this.setState({xml_shema_name: e.target.value});
    }

    changeRss(e) {
        this.setState({rss: e.target.value});
    }

    changeTelegram(e) {
        this.setState({telegram: e.target.value});
    }

    changeApiKey(e) {
        this.setState({api_key: e.target.value});
    }

    changeBlockSelector(e) {
        this.setState({block_selector: e.target.value});
    }

    changeBlockSel(value) {
        this.setState({url_formula: value});
    }

    AvailibleParsing(e) {
        e.preventDefault();
        let data = this.state.data;
        data.parsing_access = !data.parsing_access;
        this.setState({type: 'ParsingAccess', data: data}, function () {

            fetch("http://allparser.ru/backend/index.php", {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log('ok');
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        });


    }

    GetAllBots(){
        this.setState({type: 'getAllUserBots'},()=>{
            fetch('http://allparser.ru/backend/index.php', {
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(results => {
                    if (results.result === true) {
                        this.setState({botsList: results.botList});
                    }
                });
        });
    }

    componentDidMount() {


    }

    render() {
        let buttons, resTextAjax = '';
        let titleExampleBlock = this.state.titleBlockExample + this.state.title;
        let resultAjax = this.state.resultAction;

        const loadOptions = (inputValue, callback) => {
            const requestResults = this.GetAllBots();
            callback(requestResults)
        };

        var optionsSelectUrl = [
            {value: '0', label: 'domain/url'},
            {value: '1', label: 'domain/id'},
            {value: '2', label: 'domain/id-url'}
        ];

        let defOpt = optionsSelectUrl[this.state.url_formula];

        // console.log(typeof this.state.data.selected_bot);
        // console.log(this.state.botsList);
        // let defBot = this.state.botsList[this.state.data.selected_bot];

        // console.log(defBot);

        if (resultAjax) {
            resTextAjax = "Все данные сохранены в базу данных";
        }

        if (!resultAjax) {
            resTextAjax = "Что-то пошло не так!";
        }

        if (resultAjax === 3) {
            resTextAjax = "Такой сайт уже существует";
        }

        if (this.state.typeOfButton === "change") {
            buttons = <ChangeButtons
                AvailibleParsing={this.AvailibleParsing}
                ActiveParsingOrNot={this.state.data.parsing_access}
                showBlockExample={this.showBlockExample}
                saveChanges={this.saveChanges}
                deleteSites={this.deleteSites}
            />;
        } else {
            buttons =
                <CreateButtons
                    createSite={this.createSite}
                    clearForm={this.clearForm}
                />;
        }

        return (
            <div className="siteInfo-wrapper">
                <div className="container">
                    <div className="row">
                        <form className="Froma-item-list">
                            <div className="col-lg-12 row-line">
                                <span className="title_name">Название сайта:</span>

                                <input className="text-block"
                                       onChange={this.changeName}
                                       name="title"
                                       type="text"
                                       value={this.state.title}
                                       placeholder="Введите название сайта"
                                />

                            </div>
                            <div className="col-lg-12 row-line">
                                <span className="title_name">URL для парсинга:</span>
                                <input onChange={this.changeUrl} name="url" className="text-block" type="text"
                                       value={this.state.url}
                                       placeholder="Введите url страницы"/>
                                <a className="see-url" target="_blank" rel="noopener noreferrer"
                                   href={this.state.url}>()</a>
                            </div>
                            <div className="col-lg-12 row-line">
                                <span className="title_name">Главное зеркало: </span>
                                <input onChange={this.changeDomain} name="domain" className="text-block" type="text"
                                       value={this.state.domain}
                                       placeholder="Введите главное зеркало"/>

                            </div>
                            <div className="col-lg-12 row-line">
                                <span className="title_name">Селектор Заголовка: </span>
                                <input onChange={this.changeH1Selector} name="h1_selector" className="text-block"
                                       type="text"
                                       value={this.state.h1_selector}
                                       placeholder="Введите селектор Заголовка"/>
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Селектор Блока: </span>
                                <input onChange={this.changeBlockSelector} name="block_selector" className="text-block"
                                       type="text"
                                       value={this.state.block_selector}
                                       placeholder="Введите селектор Заголовка"/>
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Селектор сслыки: </span>
                                <input onChange={this.changeLinkSelector} name="link_selector" className="text-block"
                                       type="text"
                                       value={this.state.link_selector}
                                       placeholder="Введите селектор сслыки"/>
                            </div>
                            <div className="col-lg-12 row-line">
                                <span className="title_name">Селектор текста для новости: </span>
                                <input onChange={this.changeTextSelector} name="text_selector" className="text-block"
                                       type="text"
                                       value={this.state.text_selector}
                                       placeholder="Введите селектор текста для новости"/>
                            </div>
                            <div className="col-lg-12 row-line">
                                <span className="title_name">xml_shema id </span>
                                <input onChange={this.changeXmlShema} name="xml_shema" className="text-block"
                                       type="text"
                                       value={this.state.xml_shema}
                                       placeholder="Введите xml_shema"/>
                            </div>
                            <div className="col-lg-12 row-line">
                                <span className="title_name">xml_shema_name - в разработке: </span>
                                <input onChange={this.changeXmlShemaName} name="xml_shema_name" className="text-block"
                                       type="text"
                                       value={this.state.xml_shema_name}
                                       placeholder="Введите xml_shema_name"/>
                            </div>
                            <div className="col-lg-12 row-line">
                                <span className="title_name">Введите тип парсинга: </span>
                                <input onChange={this.changeRss} name="rss" className="text-block" type="text"
                                       value={this.state.rss}
                                       placeholder="Введите тип парсинга"/>
                            </div>
                            <div className="col-lg-12 row-line">
                                <span className="title_name">Id Телеграм бота: </span>
                                <input onChange={this.changeTelegram} name="telegram" className="text-block" type="text"
                                       value={this.state.telegram}
                                       placeholder="Введите Id Телеграм бота"/>
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">API Телеграм бота: </span>
                                <input onChange={this.changeApiKey} name="api_key" className="text-block" type="text"
                                       value={this.state.api_key}
                                       placeholder="Введите API key Телеграм бота"/>
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Выберите формулу для url: </span>

                                <Select
                                    className="url_formula"
                                    options={optionsSelectUrl}
                                    name="url_formula"
                                    value={optionsSelectUrl[this.state.url_formula]}
                                    onChange={(value) => this.changeBlockSel(value)}
                                    defaultValue={defOpt}
                                />
                            </div>

                            <div className="col-lg-12 row-line">
                                <span className="title_name">Выберите Бота: </span>


                                {/*<AsyncSelect*/}
                                {/*    className="botsList"*/}
                                {/*    options={loadOptions}*/}
                                {/*    name="url_formula"*/}
                                {/*    value={'sdfsd'}*/}
                                {/*    onChange={(value) => this.changeBlockSel(value)}*/}
                                {/*/>*/}
                                {/*<Select*/}
                                {/*    className="botsList"*/}
                                {/*    options={this.state.botsList}*/}
                                {/*    name="url_formula"*/}
                                {/*    value={optionsSelectUrl[this.state.url_formula]}*/}
                                {/*    onChange={(value) => this.changeBlockSel(value)}*/}
                                {/*    defaultValue={defOpt}*/}
                                {/*/>*/}
                            </div>

                            {buttons}

                            <div className="col-lg-12 text-right row-line">
                                {resTextAjax}
                            </div>
                        </form>
                    </div>
                </div>
                <div className={this.state.classListExample}>
                    <div onClick={this.hiddenBlock} className="close">x</div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title-example-block">
                                {titleExampleBlock}
                            </div>
                        </div>
                        <div className="exampleBlock-parser">

                            {this.state.dataExample.map((item, index) => (
                                <ListItemExample key={index} index={index} cost_val={item.cost_val} href={item.href}
                                                 h2={item.h2} descr={item.descr}/>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default SiteInfo;