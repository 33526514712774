import React from 'react';
import '../social/social.css';
import ListItem from '../../components/comp/list';
import SocInfo from './components/socialinfo/socialinfo';


class Social extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isScreen:'',
            listSocial:[],
            thisDataTarget:[],
            type:'getSocialList'
        };

        this.getSocialList = this.getSocialList.bind(this);
        this.clickByListItem = this.clickByListItem.bind(this);
        this.addSocial = this.addSocial.bind(this);

    }

    componentDidMount() {
        this.getSocialList();
    }

    addSocial(){

        this.setState({isScreen:'addSocial'})
    }

    getSocialList(){
        fetch("http://allparser.ru/backend/index.php?type=" + this.state.type, {
            method: 'post'
        })
            .then(res => res.json())
            .then(
                (result) => {

                    console.log(result);
                    this.setState({listSocial: result});
                    this.setState({
                        initialData: result
                    });
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    clickByListItem(e) {
        let activeClass = e.target.classList;
        let parent = e.target.parentElement;
        let index = e.target.dataset.index;

        parent.childNodes.forEach((chi) =>{
            chi.classList.remove('active');
        });


        e.target.classList.add('active');
        activeClass.add('active');

        this.setState((state) => {
            return {
                isScreen: 'info',
                thisDataTarget: this.state.listSocial[index],
                kk: index,
                activeClass: activeClass
            }
        });
    }


    render() {
        let infoSite;
        let thisDataTarget = this.state.thisDataTarget;

        if (this.state.isScreen === 'info') {
            infoSite = <SocInfo listSites={this.getSites} typeOfButton="change" key={this.state.kk} data={thisDataTarget}/>;
        }
        if (this.state.isScreen === 'addSocial') {
            infoSite = <SocInfo listSites={this.addSocial} typeOfButton="addSocial" key="addSite_unique_id_1" data={{}} />;
        }

        return (
            <div className="socialWrapper">
                <div className="container">
                    <div className="row box-shadow-wrapper">
                        <div className="col-lg-3 listSitesAndSearch">
                            <div className="search">
                                <input type="text" placeholder="Поиск"/>
                            </div>
                            <div className="list-sites">
                                <div className="title">Соц.сети, которые уже добавлены</div>
                                <ul>
                                    {this.state.listSocial.map((item,index) => (
                                        <ListItem index={index}
                                                  updateList={this.getAllSites}
                                                  clickByListItem={this.clickByListItem}
                                                  key={item.id}
                                                  id={item.id}
                                                  domain={item.domain}
                                                  title={item.title}
                                        />
                                    ))}
                                </ul>
                            </div>
                            <div className="wrapper-button">
                                <button onClick={this.addSocial} className="whiteBorder"> Добавить соц.сеть</button>
                            </div>
                        </div>
                        <div className="col-lg-9 bg-grey">
                            <h1>
                                Страница социальные сети
                            </h1>
                            <div className="form-and info">
                                {infoSite}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Social;