import React, {Component} from 'react';

class ListItemExample extends Component {
    render() {
        const {cost_val, href, h2, descr} = this.props;
        return (
            <ul >
                <li>
                    <span className="strong-block">Заголовок: </span><span>{h2}</span>
                </li>
                <li>
                    <span className="strong-block">Описание: </span><span>{descr}</span>
                </li>
                <li>
                    <span className="strong-block">Ссылка: </span><a href={href} target="_blank" rel="nofollow">{href}</a>
                </li>
                <li>
                    <span className="strong-block">Цена: </span><span>{cost_val}</span>
                </li>
            </ul>
        )
    }
}

export default ListItemExample;