import React, {Component} from 'react';
import  { Redirect } from 'react-router-dom'
import '../sites/sites.css';
import SiteInfo from './components/siteinfo/siteinfo';
import ListItem from '../../components/comp/list';


class Sites extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogined: props.isLogined || false,
            userId: props.userId || '',
            listSite: [],
            isAddSite: false,
            isScreen: '',
            title: 'Парсер сайтов',
            type: 'getAllSite'
        };

        this.addSite = this.addSite.bind(this);
        this.getAllSites = this.getAllSites.bind(this);
        this.clickByListItem = this.clickByListItem.bind(this);
        this.getSites = this.getSites.bind(this);
        this.search = this.search.bind(this);
    }

    getSites(){

        fetch("http://allparser.ru/backend/index.php", {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({listSite: result, initialData: result});
                },
                (error) => {
                    console.log(error);
                }
            );
    }


    getAllSites() {
        fetch("http://allparser.ru/backend/index.php?type=" + this.state.type, {
            method: 'post'
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({listSite: result});
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    addSite() {
        this.setState((state) => {
            return {
                isScreen: 'addSite'
            }
        });
    }

    deleteSite() {
        this.setState((state) => {
            return {
                isScreen: 'deleteSite'
            }
        });
    }

    search(e) {
        let wasState = this.state.initialData;
        let searchStr = e.target.value.toLowerCase();
        let result = wasState.filter(str => str.title.toLowerCase().indexOf(searchStr)+1 > 0 || str.domain.toLowerCase().indexOf(searchStr)+1 > 0);

        this.setState({listSite: result});
    }

    clickByListItem(e) {
        let activeClass = e.target.classList;
        let parent = e.target.parentElement;
        let index = e.target.dataset.index;

        parent.childNodes.forEach((chi) =>{
            chi.classList.remove('active');
        });


        e.target.classList.add('active');
        activeClass.add('active');


        this.setState((state) => {
            return {
                isScreen: 'info',
                thisDataTarget: this.state.listSite[index],
                kk: index,
                activeClass: activeClass
            }
        });
    }

    componentDidMount() {
        this.getSites();
    }

    render() {
        let infoSite;
        let thisDataTarget = this.state.thisDataTarget;

        if (this.state.isScreen === 'info') {
            infoSite = <SiteInfo userId={this.state.userId} listSites={this.getSites} typeOfButton="change" key={this.state.kk} data={thisDataTarget}/>;
        }
        if (this.state.isScreen === 'addSite') {
            infoSite = <SiteInfo userId={this.state.userId} listSites={this.getSites} typeOfButton="addSite" key="addSite_unique_id_1" data={{}} />;
        }

        if(this.state.isLogined){
            return (
                <div className="sitesWrapper">
                    <div className="container">
                        <div className="row customBoxShadow">
                            <div className="col-lg-3 listSitesAndSearch">
                                <div className="search">
                                    <input onChange={this.search} type="text" placeholder="Поиск"/>
                                </div>
                                <div className="list-sites">
                                    <div className="title">Сайты которые уже добавлены</div>
                                    <ul>
                                        {this.state.listSite.map((item, index) => (
                                            <ListItem index={index} updateList={this.getAllSites}
                                                      clickByListItem={this.clickByListItem} key={item.id}
                                                      id={item.id} parsing_access={item.parsing_access} domain={item.domain} title={item.title}/>
                                        ))}
                                    </ul>
                                </div>
                                <div className="wrapper-button">
                                    <button onClick={this.addSite} className="whiteBorder"> Добавить сайт</button>
                                </div>
                            </div>

                            <div className="col-lg-9 wrapper-screen-table">
                                <h1>
                                    {this.state.title}
                                </h1>
                                <div className="form-and info">
                                    {infoSite}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else{
            return(
                <div className="sitesWrapper">
                    <div className="container">
                        <div className="row customBoxShadow">
                            <Redirect to='/'  />
                        </div>
                    </div>
                </div>
            );
        }


    }
}

export default Sites;