import React from 'react';
import ReactDOM from 'react-dom';
import Main from './components/main/Main';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

import { BrowserRouter } from 'react-router-dom';

ReactDOM.render((
    <BrowserRouter >
        <Main />
    </BrowserRouter>
), document.getElementById('root'));



// import * as serviceWorker from './serviceWorker';

//ReactDOM.render(<Main />, document.getElementById('root'));

// serviceWorker.unregister();
