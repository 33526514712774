import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer';
import Home from '../../templates/home/home';
import Sites from '../../templates/sites/sites-page';
import Social from '../../templates/social/social';
import Faq from '../../templates/faq/faq';
import UserSettings from '../../templates/users/user';


class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogined: localStorage.getItem('isLogined') || false, // отвечает за регистрацию пользователя
            userId: localStorage.getItem('userId') || false,
            login: localStorage.getItem('login') || '',
            loginCompleted: false,
            pass: '',
            pass2: '',
            showRegistr: true,
            showError: false,  // flag показа ошибок
            error: '',
            registerError: ''
        };

        this.loginFunc = this.loginFunc.bind(this);
        this.logoutFunc = this.logoutFunc.bind(this);
        this.changeLogin = this.changeLogin.bind(this);
        this.changePass = this.changePass.bind(this);
        this.changePass2 = this.changePass2.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.registrationForm = this.registrationForm.bind(this);
        this.changeToReg = this.changeToReg.bind(this);
    }

    changeLogin(e) {
        e.preventDefault();
        this.setState({login: e.target.value});
    }

    changePass(e) {
        e.preventDefault();
        this.setState({pass: e.target.value});
    }

    changePass2(e) {
        e.preventDefault();
        this.setState({pass2: e.target.value});
    }

    changeToReg(e) {
        e.preventDefault();
        this.setState({showRegistr: !this.state.showRegistr});
    }

    closeModal(e) {
        e.preventDefault();
        this.setState({showError: false});
    }

    logoutFunc(e) {
        e.preventDefault();

        this.setState({
            isLogined: false,
            userId: '',
            login: '',
            pass: '',
            pass2: ''
        }, function () {
            localStorage.clear('isLogined', 'login', 'userId');
        })

    }

    loginFunc(e) {
        e.preventDefault();

        if (this.state.isLogined === false || this.state.isLogined === null) {
            this.setState({type: "login"}, (state) => {
                fetch('http://allparser.ru/backend/login.php', {
                    method: "POST",
                    body: JSON.stringify(this.state),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(results => {

                            if (results.result === true) {
                                this.setState({
                                    isLogined: 'true',
                                    sekretKey: results.secret_key,
                                    showError: false,
                                    login: this.state.login,
                                    userId: results.userId,
                                    loginCompleted: true
                                });

                                localStorage.setItem('isLogined', true);
                                localStorage.setItem('login', this.state.login);
                                localStorage.setItem('userId', results.userId);

                                // Временно показываем, что мы залогинились
                                setTimeout(() => {
                                    this.setState({
                                        loginCompleted: false
                                    });
                                }, 5000);

                            } else {
                                this.setState({error: results.error, showError: true});
                            }
                        },
                        (error) => {
                            console.log(error);
                        });

            });
        }
    }

    registrationForm(e) {
        e.preventDefault();
        let errorList = [];
        let ErrorCount = 0;

        if (this.state.pass.length <= 8) {
            errorList = errorList + 'Длинна пароля перьше 8 символов,';
            ErrorCount++;
        }

        if (this.state.pass !== this.state.pass2) {
            errorList = errorList + 'Пароли не совпадают,';
            ErrorCount++;
        }

        if (this.state.login.length <= 3) {
            errorList = errorList + 'Длинна логина меньше 4 символов,';
            ErrorCount++;
        }

        if (ErrorCount === 0) {
            this.setState({type: "register"}, (state) => {
                fetch('http://allparser.ru/backend/login.php', {
                    method: "POST",
                    body: JSON.stringify(this.state),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(results => {

                            if (results.result === true) {
                                console.log('this is arror register');
                                this.setState({showRegistr: !this.state.showRegistr});
                            } else {
                                this.setState({error: results.error, showError: true});
                            }
                        },
                        (error) => {
                            console.log(error);
                        });

            });
        } else {
            this.setState({registerError: errorList});
        }
    }


    render() {
        console.log("----------------------- NEW STEP -----------------");
        return (
            <main className="d-flex flex-column">
                <Header auth={this.state.isLogined} login={this.state.login} logoutFunc={this.logoutFunc}
                        loginRedirect={this.loginRedirect}/>
                <div className="mainWrapper">
                    <Switch>
                        <Route exact path='/'>
                            <Home registerError={this.state.registerError}
                                  showRegistr={this.state.showRegistr}
                                  changeRegOrEnter={this.changeToReg}
                                  closeModals={this.closeModal}
                                  isActive={this.state.showError}
                                  isError={this.state.error}
                                  loginFunc={this.loginFunc}
                                  login={this.changeLogin}
                                  loginCompleted={this.state.loginCompleted}
                                  registrationForm={this.registrationForm}
                                  passwd={this.changePass}
                                  passwd2={this.changePass2}
                            />

                        </Route>
                        <Route exact path='/sites'>
                            <Sites isLogined={this.state.isLogined} userId={this.state.userId}/>
                        </Route>
                        <Route exact path='/user-settings'>
                            <UserSettings userId={this.state.userId}/>
                        </Route>
                        <Route exact path='/social' component={Social}/>
                        <Route exact path='/faq' component={Faq}/>
                    </Switch>
                </div>
                <Footer/>
            </main>
        )
    }
}

export default Main;
