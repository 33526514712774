import React, {Component} from 'react';


class CreateButtons extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }

    render() {

        const {createSite, clearForm} = this.props;

        return (
            <div className="col-lg-12 text-right row-line">
                <button onClick={createSite} className="redBorder">Создать
                </button>
                <button onClick={clearForm} className="deleteParser redBorder">Очистить</button>
            </div>
        )
    }
}

export default CreateButtons;