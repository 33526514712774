import React from 'react';
import {Link} from 'react-router-dom';

let NavNonAuth = (props) => {
    return (
        <nav key="AuthNav">
            <ul className="d-flex justify-content-end">
                <li><Link to='/'>Главная</Link></li>
                <li><Link to='/faq'>FAQ</Link></li>
                <li>
                    <Link to="/" className="authorise clearStyle">Войти</Link>
                </li>
            </ul>
        </nav>
    );

};


export default NavNonAuth;