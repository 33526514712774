import React, {Component} from 'react';
import "./faq.scss";


class Faq extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {

        return (
            <div className="faqWrapper">
                <div className="left-block">
                    <ul>
                        <li><a href="#one" className="link-to-block">Регистрация</a></li>
                        <li><a href="#two" className="link-to-block">Создание </a></li>
                    </ul>
                </div>
                <div className="right-block">
                    <h1>
                        FAQ - инструкция к применению
                    </h1>

                    <section>
                        <h2>

                        </h2>
                    <div className="wrapper-text">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias consectetur eveniet expedita fugiat nisi obcaecati quidem reprehenderit, temporibus voluptate voluptates. Enim ex excepturi facilis id incidunt iste nisi reprehenderit voluptatem.

                        </p>
                    </div>
                    </section>


                </div>
            </div>
        );
    }
}

export default Faq;