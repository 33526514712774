import React, {Component} from 'react';
import NavNonAuth from '../navigation/navNonAuth';
import NavAuth from '../navigation/navAuth';
import Logo from '../../img/all.svg';

class Header extends Component {

    render() {
        let authnav = {};

        if (this.props.auth === 'true') {
            authnav = <NavAuth login={this.props.login} logoutFunc={this.props.logoutFunc} key="AuthNavigation"/>;
        } else {
            authnav = <NavNonAuth loginRedirect={this.props.loginRedirect} key="NotAuthNavigation"/>;
        }


        return (
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <a href="/" className="logo">
                                <img src={Logo} alt=""/>
                            </a>
                        </div>
                        <div className="col-lg-9 d-flex justify-content-end align-items-center">
                            <div className="navigationWrapper">
                                { authnav }
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;